import {
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { CurrentPassengerState } from "../recoil/PassengerAtom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TripCard from "../components/TripOverview/TripCard";
import { Download, InsertDriveFile, Logout } from "@mui/icons-material";
import { PortalInformation } from "../types/portal";
import { getPortalFileBlob, getPortalInformation } from "../services/passengerService";

import parse from "html-react-parser";

export default function Dashboard() {
  const [currentPassenger, setCurrentPassenger] = useRecoilState(
    CurrentPassengerState
  );
  const [portalInfo, setPortalInfo] = useState<PortalInformation>();
  const navigate = useNavigate();
  useEffect(() => {
    switch (currentPassenger.onBoardingStatus) {
      case 0:
        navigate(`/${currentPassenger.ship?.name}/ContactInfo`);
        break;
      case 1:
        navigate(`/${currentPassenger.ship?.name}/TravelInfo`);
        break;
      case 2:
        navigate(`/${currentPassenger.ship?.name}/MedicalInfo`);
        break;
      case 3:
        navigate(`/${currentPassenger.ship?.name}/AquaticInfo`);
        break;
      case 4:
        navigate(`/${currentPassenger.ship?.name}/Activities`);
        break;
      case 5:
        navigate(`/${currentPassenger.ship?.name}/Drinks`);
        break;
      case 6:
        navigate(`/${currentPassenger.ship?.name}/Food`);
        break;
      default:
        getPortalInformation(currentPassenger.ship?.id).then((data) => {
          setPortalInfo(data);
        });
        break;
    }
  }, [
    currentPassenger.id,
    currentPassenger.onBoardingStatus,
    navigate,
    currentPassenger.ship?.name,
    currentPassenger.ship?.id
  ]);
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TripCard
            startDate={currentPassenger.trip?.startDate}
            startLocation={currentPassenger.trip?.startLocation}
            endDate={currentPassenger.trip?.endDate}
            endLocation={currentPassenger.trip?.endLocation}
            firstName={currentPassenger.firstName}
            lastName={currentPassenger.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 4,
              borderTopColor: "#b47f49",
              borderTopWidth: "4px",
              borderStyle: "solid",
              borderRightWidth: "0px",
              borderLeftWidth: "0px",
              borderBottomWidth: "0",
            }}
            elevation={12}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Thank you for your submission! A member from our staff will
                  reach out with any questions.
                </Typography>
                <Stack direction="row-reverse">
            <Button
              startIcon={<Logout />}
              onClick={() =>
                setCurrentPassenger({
                  id: "",
                  email: "",
                  joinCode: "",
                  onBoardingStatus: -1,
                })
              }
              variant="contained"
            >
              Logout
            </Button>
          </Stack>
              </Grid>

            </Grid>
          </Paper>
        </Grid>
        {portalInfo?.files && portalInfo.files.length > 0 && (
          <Grid item xs={12}>
            <Paper sx={{ p: 4, borderTopColor: "#b47f49", borderTopWidth: "4px", borderStyle: "solid", borderRightWidth: "0px", borderLeftWidth: "0px", borderBottomWidth: "0" }} elevation={12}>
              <Typography variant="h4" gutterBottom>Important Information</Typography>
              <Stack spacing={2}>
                {portalInfo.files.map((file) => (
                  <Paper 
                    key={file.id}
                    sx={{ 
                      p: 2, 
                      display: 'flex', 
                      alignItems: 'center',
                      bgcolor: 'background.default',
                      '&:hover': {
                        bgcolor: 'action.hover',
                        cursor: 'pointer'
                      }
                    }} 
                    elevation={2}
                    onClick={() => getPortalFileBlob(portalInfo.id, file.id, currentPassenger.ship?.id)}
                  >
                    <InsertDriveFile color="primary" sx={{ fontSize: 40, mr: 2 }} />
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                      {file.helperName}
                    </Typography>
                    <Download color="primary" />
                  </Paper>
                ))}
              </Stack>
            </Paper>
          </Grid>
        )}
        {portalInfo?.knowBeforeYouGo && (
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 4,
                borderTopColor: "#b47f49",
                borderTopWidth: "4px",
                borderStyle: "solid",
                borderRightWidth: "0px",
                borderLeftWidth: "0px",
                borderBottomWidth: "0",
              }}
              elevation={12}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Know Before You Go
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                    {parse(portalInfo.knowBeforeYouGo)}
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}

        <Grid item xs={12}>
          
        </Grid>
      </Grid>
    </Container>
  );
}
